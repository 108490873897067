<template>
  <BaseCard :styles="{ borderRadius: '15px', padding: '0' }">
    <router-link :to="link ? link : ''" class="card" :style="cardStyle">
      <div class="card__content">
        <div class="card__header">
          <CircleButton
            height="36px"
            width="36px"
            :bg-color="color"
            :color="bgColor"
          />
        </div>
        <img loading="lazy" :src="image" :alt="alt" />
        <h4 class="card__title" v-html="title"></h4>
        <div class="card__excerpt" v-html="description"></div>
      </div>
    </router-link>
  </BaseCard>
</template>

<script>
import CircleButton from "@/components/Buttons/CircleButton.vue";
import BaseCard from "@/components/Cards/BaseCard.vue";
const colors = require("/colors.config.json");
export default {
  name: "Maincard",
  components: { CircleButton, BaseCard },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    imageFileName: {
      type: String,
      default: "logo.png",
    },
    image: {
      type: String,
      default: "",
    },
    beforeTitle: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: `${colors.white}`,
    },
    color: {
      type: String,
      default: `${colors.white}`,
    },
    alt: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
  },
  mounted() {
    console.log(this.image);
  },
  watch: {
    image() {
      console.log(this.image);
    },
  },
  computed: {
    cardStyle() {
      return {
        "--background-color": this.bgColor,
        "--color": this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  border: none;
  height: 100%;
  text-decoration: none;

  .card__content {
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 10px 10px;
    // height: calc(100% - 40px);
    height: 100%;
    min-height: 227.6px;
    background-color: var(--background-color);

    .card__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .card__title {
      font-size: 1.2rem !important;
    }
    .card__title,
    .card__excerpt {
      margin-bottom: 0;
      margin: 0px;
      font-size: 0.75rem;
      white-space: normal;
      word-wrap: break-word;
      hyphens: auto;
      padding: 0;
      text-align: left;
      color: var(--color);
      line-height: 18px;

      @media (min-width: 1600px) {
        font-size: 0.875rem;
      }

      ::v-deep {
        * {
          margin-bottom: 0;
          margin: 0px;
          font-size: 0.75rem;
          white-space: normal;
          word-wrap: break-word;
          hyphens: auto;
          padding: 0;
          text-align: left;
          color: var(--color);
          line-height: 18px;

          @media (min-width: 1600px) {
            font-size: 0.875rem;
          }
        }

        *:last-child {
          margin-bottom: 0px;
        }
      }
    }

    img {
      max-height: 100px;
      object-position: center;
      object-fit: contain;
      margin-bottom: 15px;
      vertical-align: middle;
      border-style: none;
    }

    h4 {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0 0 0px;
      white-space: normal;
      word-wrap: break-word;
      hyphens: auto;
      font-family: "Oswald", sans-serif;
      line-height: 1.2;
      padding: 0;
      color: var(--color);
      text-align: left;

      @media (min-width: 1600px) {
        font-size: 1rem;
      }
    }

    &:hover {
      transition: all 0.3s ease;
      background-color: var(--color);
      cursor: pointer;
      h4,
      p,
      .card__title {
        color: black;
      }
      .card-arrow {
        transition: all 0.3s ease;
        background-color: var(--color) !important;
        color: var(--background-color) !important;
      }
      .card__excerpt {
        color: black;
        
        ::v-deep {
          * {
            color: black;
          }
        }
      }
    }
  }
}
</style>
