<template>
  <div class="settings content-padding">
    <div v-if="Object.keys(moduleData).length" class="row">
      <div class="col-md-6">
        <LabelInput
          font-family="Oswald"
          class="label-input mb-4"
          type="text"
          :base-card="true"
          :label="labels.title"
          :value="moduleData.title"
          @changed="dataChanged({ value: $event, key: 'title' })" />

        <TextEditorCard
          class="text-editor mb-4"
          :title="labels.description"
          :value="moduleData.description"
          @changed="dataChanged({ value: $event.value, key: 'description' })" />

        <TextEditorCard
          class="text-editor mb-4"
          :title="labels.excerpt"
          :value="moduleData.excerpt"
          @changed="dataChanged({ value: $event.value, key: 'excerpt' })" />
      </div>
      <div class="col-md-6">
        <BaseCard class="monetization-bundle mb-4">
          <h6>
            {{ labels.monetization_bundle }}
          </h6>
          <FormulateInput
            v-if="availableBundles.length"
            type="select"
            name="monetizationBundle"
            error-behavior="blur"
            validation="required"
            v-model="localMonetizationBundle"
            :options="availableBundles"
            :disabled="Object.keys(moduleData).length ? false : true"
            @input="dataChanged({value: $event, key: 'monetizationBundle'})"
          />
        </BaseCard>
        <LabelButtonCard
          class="label-button-card mb-4"
          :header-title="labels.location_content_is_used"
          :list="locationContentList"
          :settings="locationContentCardSettings" />

        <div class="settings-card row mb-4">
          <div class="setting-main-card col-6">
            <MainCard
              class="h-100"
              :title="displayTitle"
              :description="moduleData.excerpt"
              :bgColor="moduleData.color" />
          </div>
          <div class="setting-card col-6">
            <Card
              :title="displayTitle"
              :status-lable="moduleData.free"
              :description="moduleData.excerpt"
              :bookmark="labels.bookmark"
              :bgColor="moduleData.color" />
          </div>
        </div>

        <ColorPickerCard
          v-if="!singleData.parent"
          class="mb-4"
          :title="labels.card_color"
          :value="moduleData.color"
          @changed="dataChanged({ value: $event, key: 'color' })" />

        <LabelInput
          font-family="Oswald"
          class="label-input mb-4"
          type="text"
          :base-card="true"
          :label="labels.prefix"
          :value="moduleData.number"
          @changed="dataChanged({ value: $event, key: 'number' })" />

        <LabelInput
          font-family="Oswald"
          class="label-input mb-4"
          type="checkbox"
          :base-card="true"
          :label="labels.in_construction"
          :value="moduleData.construction == 'true' ? true : false"
          :checked="moduleData.construction == 'true' ? true : false"
          @changed="dataChangedConstruction({ value: $event, key: 'construction' })" />
      </div>
      <div class="col-12">
        <ArchiveCard :labels="labels" :title="moduleData.title" @archive="archiveModule" :type="labels.module"/>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditorCard from "@/components/Input/TextEditorCard";
import LabelButtonCard from "@/components/Cards/LabelButtonCard";
import LabelInput from "@/components/Input/LabelInput";
import MainCard from "@/components/Cards/MainCard";
import Card from "@/components/Cards/Card";
import ColorPickerCard from "@/components/Input/ColorPickerCard";
import ArchiveCard from "@/components/Cards/ArchiveCard";
import BaseCard from "@/components/Cards/BaseCard";

import { library } from "@fortawesome/fontawesome-svg-core";
import { customExternalLink } from "@/assets/icons";

import { mapGetters, mapState, mapMutations, mapActions } from "vuex";

library.add(customExternalLink);

export default {
  name: "Settings",
  components: {
    TextEditorCard,
    LabelButtonCard,
    LabelInput,
    MainCard,
    Card,
    ColorPickerCard,
    ArchiveCard,
    BaseCard
  },
  data() {
    return {
      locationContentCardSettings: {
        minWidth: "220px",
        label: { style: { flex: "0 0 80px" } },
        actions: {
          icon: { style: { flex: "0 0 20px", minWidth: "20px" } },
          button: { style: { flex: "1 1 auto" } },
        },
      },
      locationContentList: [
        {
          label: "Lorem ipsum",
          actions: [
            {
              type: "icon",
              icon: "custom-external-link",
              action: () => {},
            },
            { type: "label", label: "Linked" },
          ],
        },
      ],
      availableBundles: [],
      localMonetizationBundle: ''
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    ...mapState("_module", {
      moduleData: "stateSingleData",
      singleData: "singleData",
    }),
    displayTitle() {
      if (this.moduleData.number?.length) return `${this.moduleData.number} ${this.moduleData.title}`;

      return this.moduleData.title;
    }
  },
  mounted() {
    this.getAvailableBundles();
  },
  methods: {
    ...mapMutations("_module", ["updateStateSingleData"]),
    ...mapActions("_module", ["archive"]),
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("monetizationBundle", ["getMonetizationBundleList"]),
    action() {
      console.log("close");
    },
    dataChanged(data) {
      this.updateStateSingleData({
        ...this.moduleData,
        ...{ [data.key]: data.value },
      });
    },
    dataChangedConstruction(data) {
      let val = 'true'
      if(!data.value) val = 'false'
      this.updateStateSingleData({
        ...this.moduleData,
        ...{ [data.key]: val },
      });
    },
    archiveModule() {
      this.archive({ id: this.$route.params.id }).then(res=>{
        if(res.success){
            this.$router.push({ name: "module-overview"})
        } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: 'res.error',
            });
          }
      })
    },
    getAvailableBundles() {
      this.getMonetizationBundleList({}).then((res) => {
        if (res.success) {
          res.data.data.forEach((bundle) => {
            this.availableBundles.push({
              value: bundle._id,
              label: bundle.title,
              content: bundle.content,
            });
          });
        } else {
            this.addNotification({
              variant: "danger",
              msg: res.error,
              labels: this.labels,
            });
          }
        }).catch((err) => console.log(err));
    }
  },
  watch: {
    moduleData(newVal) {
      if (newVal.monetizationBundle) this.localMonetizationBundle = newVal.monetizationBundle;
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  .fa-times {
    cursor: pointer;
    border-radius: 50%;
    background: $light-gray;
    color: $gray;
    height: 32px;
    width: 32px;
    padding: 7px;
  }

  .monetization-bundle {
    h6 {
      font-family: 'Oswald';
      font-weight: 400;
      font-size: 1.1em;
      margin-bottom: 0px;
      border-bottom: 1px solid #e7e7e7;
      margin: 0 -25px 25px;
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px;
    }

    ::v-deep {
      .formulate-input-element {
        max-width: unset;
        width: 100%;

        select {
          width: 100%;
        }
      }
    }
  }
}
</style>
